.check {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}

.priceInfoBlock,
.totalBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  white-space: nowrap;

  & > span:nth-child(even) {
    text-align: right;
  }
}

.checkItemText {
  color: var(--color-pastel);
  font-size: 16px;
  line-height: 140%;
}

.checkItemValue {
  color: var(--color-red);
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  &:nth-child(2) {
    color: var(--color-pastel);
  }
}

.currentPlanInfoLine {
  opacity: 1;
  margin: 16px 0;
  color: #e7e4de;
}

.checkTotalText {
  color: var(--color-foreground);
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  font-family: var(--font-family-secondary);
  letter-spacing: 0.2px;
}

.checkTotalValue {
  color: var(--color-red);
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  font-family: var(--font-family-secondary);
  letter-spacing: 0.2px;
}
