@use '../../../../styles/theme';
@use '../../../../styles/bootstrap';
@use '../../../../styles/utils';

.modal {
  :global(.modal-content) {
    align-items: center;
  }
}

.imageContainer {
  margin-bottom: 24px;
}

.title {
  z-index: 1;
  margin: 0 0 8px;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  font-family: var(--font-family-secondary), serif;
  text-align: center;
}

.description {
  color: theme.$color-foreground-lighter-3;
  font-size: 14px;
  line-height: 140%;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

@include bootstrap.media-breakpoint-down(sm) {
  .backdrop {
    @include utils.cover-area-under-modal(theme.$color-background);
  }

  .container {
    display: flex;
    justify-content: flex-end;

    &:global(.fade) {
      transition: transform 0.3s ease-in-out;

      &:global(.show) {
        transform: none;
      }

      &:not(:global(.show)) {
        transform: translateY(100%);
        opacity: 1;
      }

      .modal {
        transform: none;
        transition: none;
      }
    }
  }

  .modal {
    align-items: flex-end;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 0;

    :global(.modal-content) {
      border: 0 !important;
      border-radius: 24px 24px 0 0;
    }
  }
}
