.emailFormGroup {
  margin-bottom: 16px;
}

.messageFormControl {
  height: 180px;
}

.errorText {
  margin-top: 8px;
  font-size: 0.875rem;
}
