$heightTitle: 28px;

.planCard {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 16px;
  background: var(--color-foreground-lighter-4);
  padding: 0 1px 1px 1px;
  width: 100%;
  height: 108px;
  overflow: hidden;
  color: var(--color-foreground-lighter-4);

  & .priceUnitFull,
  .currentPrice p {
    color: var(--color-foreground-lighter-4);
  }
  & h4:first-child {
    color: var(--color-pastel);
  }
}

.skeletonWrapper {
  display: flex;
  align-items: center;
  border-radius: 0 0 15px 15px;
  background: var(--color-white);
  padding: 16px;
  width: 100%;
  height: calc(100% - $heightTitle);
}

.skeletonWrapper {
  display: block;
  margin-top: auto;
  padding-top: 10px;
  text-align: start;

  span {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.titleSkeleton {
  width: 75%;
}

.descriptionSkeleton {
  width: 35%;
}

.skeletonCard {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
