@use '../../../../styles/utils';
@include utils.pulse-button-animation(var(--color-accent-2-rgb));

.titleSection {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: var(--mg-h1-fs);
  line-height: 39.2px;
  font-family: var(--font-family-secondary), serif;
}

.extraDiscount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid #83a79a;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  svg {
    margin-right: 20px;
  }
}

.declineDiscount {
  box-shadow: inset 0 0 0 2px var(--color-foreground-lighter-4);
  background: var(--color-background-lighter-1);
  --sc-title-background-checked: #ffd675;
  --sc-discount-percantage-active-bg: #ffb800;
  --sc-title-font-color: var(--color-pastel);
  --checked-box-shadow: inset 0 0 0 2px var(--color-foreground-lighter-4);
  --sc-title-font-color-checked: var(--color-foreground);
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  width: 100%;
}

.paymentButton {
  animation: pulse-button 2s infinite;

  &,
  &:active,
  &:focus,
  &:visited,
  &:hover {
    align-self: center;
    max-width: 327px;
  }
}
