.content {
  height: 100%;
  @media (max-height: 768px) {
    height: auto;
  }
}

.leaves {
  position: relative;
  margin-bottom: 24px;
  text-align: center;
}

.title {
  margin-bottom: 16px;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  font-family: var(--mg-secondary-ff), serif;
}

.description {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 21px;
}

.progressContainer {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 44px;
  border-radius: 24px;
  background: #e7e4de;
  height: 20px;
  overflow: hidden;
  color: #fff;
  text-align: center;
}

.progressFill {
  position: absolute;
  background: var(--mg-color-primary);
  height: 100%;
}

.progressTitle {
  position: relative;
  margin: auto;
  font-weight: 700;
}

.additionalBlock {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}

.loader {
  display: block;
  animation:
    loader-first-1 0.8s infinite linear alternate,
    loader-first-2 1.6s infinite linear;
  border: 2px solid var(--mg-color-primary);
  border-radius: 50%;
  aspect-ratio: 1;
  width: 20px;
}

@keyframes loader-first-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes loader-first-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.bottomContainer {
  flex-grow: 2;
  align-content: flex-end;
  align-self: auto;
  //margin-bottom: 16px;
  text-align: center;
}

.button {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
