.container {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 200ms ease-in-out;
  border: 2px solid var(--accent-green-900, #356b61);
  border-top: none;
  border-radius: 0 0 16px 16px;
  background: white;
  width: 100%;
  overflow: hidden;
}

.container.open {
  grid-template-rows: 1fr;
}
