@use '../../../styles/theme';

#nprogress {
  .bar {
    background-color: theme.$color-foreground;
  }

  .peg {
    box-shadow:
      0 0 10px theme.$color-foreground,
      0 0 5px theme.$color-foreground;
  }
}
