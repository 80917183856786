.paypalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffc439;
  width: 100%;
  height: 48px;
  & svg {
    width: 95.2px;
    height: 24px;
  }
}
