.selectorContainer {
  border: 1px solid var(--color-background-darker);
  border-radius: 16px;
  background: var(--color-white);
  padding: 16px 0;
}

.selectorContainerCompact {
  border: none;
  background: transparent;
  padding: 0;
}

.containerSkeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.skeletonItem {
  border-radius: 8px;
  width: 100%;
  &:not(:first-child) {
    margin-left: 8px;
  }
}

.skeletonItemSubmitButton {
  margin-top: 24px;
  border-radius: 16px;
  width: 100%;
}
