@use '../../../styles/theme';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: theme.$color-background-darker;
  padding: 0 16px;
  height: theme.$header-height;
}

.leftContainer {
  flex-shrink: 0;
  margin-right: 16px;
}

.title {
  flex: 1;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.rightContainer {
  flex-shrink: 0;
  margin-left: 16px;
}
