@use '../../../../styles/bootstrap';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 520px;
  height: 100%;
  color: var(--color-foreground);
  text-align: center;

  h2 {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    font-family: var(--font-family-secondary);
    letter-spacing: 0.28px;
    text-align: center;
  }

  h3 {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    font-family: var(--font-family-secondary);
  }

  p {
    margin-bottom: 8px;
    text-align: left;
  }
}

.imageWrapper {
  margin-bottom: 16px;
  overflow: hidden;
}

@include bootstrap.media-breakpoint-down(sm) {
  .image {
    display: none;
  }
  .imageMobile {
    display: initial;
  }
}
@include bootstrap.media-breakpoint-up(sm) {
  .image {
    display: initial;
  }
  .imageMobile {
    display: none;
  }
}
