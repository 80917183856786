@use '../../../../styles/theme';

.paymentSection {
  position: relative;
  z-index: 3;
  background-color: theme.$color-white;
}

.policyWrapper {
  height: 66px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.policy {
  color: theme.$color-pastel;
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
}

.paymentSelectorSection {
  margin: 0 -24px;
  background: var(--color-background-lighter-1);
  padding: 32px 24px;
  width: calc(100% + 48px) !important;
}

.paymentSelectorSection_title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  font-family: var(--font-family-secondary), serif;
  letter-spacing: 0.28px;
}
