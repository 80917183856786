@use '../../../../../styles/bootstrap.scss';

.priceUnitFull {
  margin: auto 4px 0;
}

.text {
  color: var(--color-pastel);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  white-space: nowrap;
}

.textChecked {
  color: var(--color-foreground);
  white-space: nowrap;
}

.discountChecked {
  color: var(--color-white);
}

.discountSpecialOfferChecked {
  color: var(--color-white);
}

.wrapper {
  display: flex;
  grid-gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4px;
}

.defaultDiscount {
  margin: 0 !important;
  padding: 0 4px 0 0 !important;
}

.alignEnd {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.rightBlockContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  align-items: flex-end;
}
