.modalContent {
  :global(.modal-content) {
    align-self: center;
    margin: 0 auto;
    border-radius: 24px;
    padding: 32px 16px;
    width: 343px;
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  left: 16px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  font-family: var(--font-family-secondary), serif;
  letter-spacing: 0.2px;
}

.subTitle {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.buttonContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-top: 24px;
  border-radius: 16px;
  background: var(--color-background-lighter-1);
  padding: 16px 12px;
  max-width: 400px;
}

.buttonContentTitle,
.buttonContentSubTitle {
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.16px;
}

.buttonContentSubTitle {
  margin-top: 4px;
  font-weight: 700;
}

.priceLabel {
  border-radius: 4px;
  background: var(--color-accent-1);
  padding: 2px 4px;
  font-size: 14px;
}

.button {
  margin-top: 12px;
}
