@use '../../../../styles/theme';

.container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: theme.$color-white;
  height: 60px;
}

.frame {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  opacity: 0;
}
