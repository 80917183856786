@use '../../../../styles/theme.scss';

.container {
  top: auto;
  bottom: 0;
  min-height: calc(100% - 24px);
  max-height: calc(100% - 24px);

  :global(.modal-content) {
    border-radius: 24px 24px 0 0 !important;
    padding-bottom: 16px;
    min-height: 90%;
  }
}

.planPriceBlock,
.planDiscountBlock,
.totalBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: theme.$color-pastel;
  font-size: 14px;
}

.currentPlanInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.planPriceBlock {
  margin-bottom: 8px;
  span:last-child,
  del {
    color: theme.$color-pastel;
    font-weight: 700;
    line-height: 143%;
  }
}

.planDiscountBlock {
  span:last-child {
    color: theme.$color-accent-2;
    font-weight: 700;
    line-height: 143%;
  }
}

.currentPlanInfoLine {
  opacity: 1;
  margin: 8px 0;
  color: #e7e4de;
}

.totalBlock {
  em {
    color: theme.$color-foreground;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 143%;
  }
}

.disclaimer {
  flex: 1;
  margin-bottom: 8px;
  color: theme.$color-foreground-lighter-4;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
}

.hideLoading {
  display: none;
}

.backdrop {
  backdrop-filter: none;
  background: rgba(75, 75, 75, 0.8);
}

.line {
  position: absolute;
  top: 8px;
  opacity: 1;
  margin: 0;
  border-radius: 5px;
  width: 43px;
  height: 4px !important;
  color: #d9d9d9;
}

.headerCloseButton {
  position: absolute;
  top: 4px;
  left: 4px;
  color: #e7e4de;
}

.paymentButtonsContainer {
  flex: 1 1;
  margin-top: 8px;
}

.paymentButton {
  margin: 0 auto 8px auto;
  border: 2px solid theme.$color-foreground-lighter-4;
  width: 327px;
  height: 60px;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    border: 2px solid theme.$color-green;
  }
}

.googlePayButton {
  border: none;
  background-color: #000 !important;
}

.paypalButton {
  border: none;
  background-color: #ffc439 !important;
}

.debitCardButton {
  border: none;
  background-color: #f5f4f1 !important;
}

.logosContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin: 4px 0;
  height: 20px;
}

.paypalLogo {
  width: 21.46px;
  height: 22px;
}

.visaLogo {
  width: 49px;
  height: 16px;
}

.mastercardLogo {
  width: 26px;
  height: 20px;
}

.amexLogo {
  width: 28px;
  height: 20px;
}

.unionpayLogo {
  width: 31px;
  height: 20px;
}

.lockIcon {
  margin-right: 3px;
  width: 12px;
  height: 12px;
}

.footer {
  p:first-child {
    color: theme.$color-foreground;
    font-weight: 700;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
  }
  p:last-child {
    display: flex;
    align-items: center;
    margin-left: 4.8px;
    color: theme.$color-pastel;
    font-weight: 400;
    font-size: 12px;
    line-height: 167%;
    text-align: center;
  }
}

.loaderContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: theme.$local-grey;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.loader {
  animation: loader linear 2s infinite;
  margin-bottom: 22px;
}

.hidePayments {
  display: none;
}

.safariRedirectPrompt {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &Graphic {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    margin-bottom: 96px;
    padding: 0 24px;
    font-weight: bold;
    font-size: 14px;

    figcaption {
      margin-top: 8px;
      border: 2px solid white;
      border-radius: 16px;
      padding: 12px 16px;
      width: 216px;

      &::before,
      &::after {
        display: block;
        margin: 12px 0;
        border-top: 1px solid grey;
        content: '';
      }
    }
  }

  &Details {
    margin-bottom: 24px;
    text-align: center;

    h2 {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    p {
      color: theme.$local-grey;
      font-size: 14px;
    }
  }

  &BackHint {
    margin-top: auto;
    color: theme.$local-grey;
    font-size: 14px;
    text-align: center;
  }
}

@keyframes loader {
  0% {
    transform: rotate(-360deg);
  }
}

@media (min-width: 575.98px) {
  .container {
    min-width: 400px;
    min-height: 500px;

    :global(.modal-content) {
      border-radius: 24px !important;
      padding-bottom: 16px;
      min-width: 400px;
      min-height: 500px;
    }
    :global(.modal-dialog) {
      min-width: 400px;
    }
  }
}
