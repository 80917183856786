@use '../../../../styles/utils';
@include utils.pulse-button-animation(#356b61);

.container {
  :global(.modal-content) {
    border-radius: 0;
    background: var(--color-background);
    height: 100%;
    text-align: center;
    & > h2 {
      margin-bottom: 16px;
      color: var(--color-foreground);
      font-weight: 800;
      font-size: 28px;
      line-height: 120%;
      letter-spacing: 0.01em;
    }
    & > p {
      color: var(--color-pastel);
      font-size: 16px;
    }
    a:last-child {
      text-decoration: underline;
    }
  }
}

.accessBlock {
  border-radius: 16px;
  background: var(--color-white);
  padding: 16px;
  width: 100%;
  grid-gap: 8px;
  margin-top: -16px;

  p {
    display: flex;
    white-space: nowrap;
    b {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      margin-right: 0;
      margin-left: auto;
      &:focus,
      &:active {
        border: 1px solid var(--color-pastel);
        border-radius: 5px;
      }
    }
  }
}

.disclaimer {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  margin: 8px auto 0 0;

  & > span {
    color: var(--color-pastel);
    font-weight: 400;
    font-size: 12px;
    line-height: 167%;
  }
}

.footer {
  margin: auto 0 0 0;
  color: var(--color-pastel);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  p:first-child {
    color: var(--color-foreground);
  }
}

.downloadButton {
  animation: linear 2s infinite pulse-button;
  margin: 16px auto;
}

.credentials {
  margin-top: 16px;
  font-size: 14px;
}

.contactUs {
  margin: auto auto 16px;
  max-width: 295px;
  font-size: 14px;

  & > :first-child {
    color: var(--color-foreground);
  }
}

@media (max-height: 573px) {
  .container {
    :global(.modal-content) {
      padding: 16px 24px;
      & > h2 {
        margin-bottom: 8px;
      }

      .credentials {
        margin-top: 8px;
      }
    }
  }

  .accessBlock {
    & > hr {
      margin: 8px 0;
    }
  }
  .footer {
    .downloadButton {
      margin: 8px auto;
    }
  }

  .contactUs {
    margin-bottom: 0;
  }

  .disclaimer {
    margin-top: 4px;
  }

  .image {
    margin-bottom: -16px;
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 576px) {
  .container {
    :global(.modal-content) {
      border-radius: 16px;
    }
  }
}
