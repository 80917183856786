.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 520px;
  height: 100%;
  color: var(--color-foreground);
  text-align: center;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    font-family: var(--font-family-secondary);

    em {
      color: var(--color-accent-2);
      font-style: normal;
    }
  }

  p {
    margin-bottom: 16px;
  }
}

.subtext {
  margin-bottom: 12px;
  color: var(--color-accent-2);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: var(--font-family-secondary);
}

.image {
  display: block;
  margin: 0 auto 16px;
  border-radius: 160px;
  width: 213px;
  height: 213px;
  overflow: hidden;
}

.listAnswers {
  border: 2px solid var(--color-accent-2);
  border-radius: 24px;
  background: var(--color-white);
  padding: 24px;
  grid-gap: 16px;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    align-items: center;
    grid-gap: 22px;

    > :first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: var(--color-accent-2);
      width: 32px;
      height: 32px;
      color: var(--color-white);
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }

    p {
      flex: 1;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: start;
    }
  }
}
