@use '../../../../styles/theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-top: 16px;
  border: 1px solid var(--color-foreground-lighter-4);
  border-radius: 16px;
  background: var(--color-white);
  padding: 24px 16px;

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    font-family: var(--font-family-secondary);
  }
}
.imageMap {
  margin: 16px 0;
  width: 100%;
}
