.info {
  border: 1px solid var(--color-background-darker);
  border-radius: 16px;
  background: var(--color-white);
  padding: 16px;
  width: 100%;
  color: var(--color-foreground);
}

.guaranteeTitle {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
}

.transactionsEncrypted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--color-pastel);
  white-space: nowrap;

  p {
    margin-left: 3px;
    font-size: 14px;
    line-height: 140%;
  }
}

.logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: auto;
  margin-bottom: 12px;
  width: 100%;
  height: 40px;

  svg {
    height: 24px;
  }
}

.refundRate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.refundDescription {
  flex: 1;
  margin-left: 16px;
  color: var(--color-foreground);
  text-align: left;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 133%;
    font-family: var(--font-family-secondary), serif;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 142%;
  }
}

.moneyHand {
  width: 60.077px;
  height: 56px;
}

.lockIcon {
  width: 16px;
  height: 16px;
}
