@use '../../../../styles/theme';
@use '../../../../styles/utils';
@include utils.pulse-button-animation(theme.$color-accent-2);

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -8px;
  border: 1px solid theme.$color-background-lighter-1;
  border-radius: 16px;
  background-color: theme.$color-white;
  padding: 12px 14px;
  height: 72px;
  grid-gap: 8px;
  p:first-child {
    color: theme.$color-pastel;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.timer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: theme.$color-foreground;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  font-variant-numeric: tabular-nums;
}

.linkButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: pulse-button 2s infinite;
  border-radius: 8px;
  background: theme.$color-accent-2;
  padding: 10px 0;
  width: 100%;
  color: theme.$color-white;
  font-weight: 700;
  font-size: 14px;
}

.timerEnd {
  color: theme.$color-pastel;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  text-align: left;
}

.discountSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
  border-radius: 4px;
  background: linear-gradient(89.88deg, #eb001b 0.09%, #f97080 99.9%);
  padding: 8px 16px;
  color: theme.$color-white;
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  font-variant-numeric: tabular-nums;
  text-align: center;

  svg {
    margin: 0 12px 0 0;
  }
}

.bannerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
