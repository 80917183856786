.container {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 8px;
  justify-content: center;

  & > :first-child {
    flex: none;
  }
}

.text {
  color: var(--color-foreground);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
}

.title {
  color: var(--color-foreground);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.subsection {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
}
