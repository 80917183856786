.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 0;
  width: 40px;
  height: 40px;

  &,
  &:hover,
  &:focus {
    background-color: var(--color-white);
  }

  &,
  &:hover,
  &:active,
  &:focus {
    color: var(--color-foreground);
  }

  &:active {
    background-color: var(--color-background-lighter-1);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-background-lighter-2);
      color: var(--color-foreground);
    }
  }
}
