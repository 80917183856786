.paymentMethodOption_layout {
  padding: 0 16px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-background-darker);
  }
}

.paymentMethodOption_title {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px solid #c8c4bb;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    content: '';
  }
  &:first-child {
    padding-left: 28px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }
}

.paymentMethodOption_title_selected {
  &::before {
    box-shadow: inset 0 0 0 5px #fff;
    border-color: var(--color-accent-2);
    background-color: var(--color-accent-2);
  }
}

.paymentMethod_contentList {
  margin-top: 16px;
  grid-gap: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  text-align: left;
}

.paymentMethodOption_contentItem {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  &::before {
    display: block;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    content: url('../../../../../../assets/images/mimika-gift/checkmark.svg');
  }
}

.paymentMethod_content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 200ms ease-in-out;
  overflow: hidden;
}

.paymentMethod_content_selected {
  grid-template-rows: 1fr;
  padding-bottom: 16px;
}

.paypalButton {
  border-radius: 16px;
  background-color: #f6c657;
  height: 64px;
  color: white;
  font-weight: bold;
}
