.container {
  position: relative;
  width: 100%;
  scroll-behavior: smooth;

  section {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    width: 100%;
  }
}

.headerSection,
.footerSection {
  position: sticky;
  transform: translateY(0px);
  opacity: 1;
  z-index: 5;
  transition: transform var(--animate-duration);
}

.headerSection {
  top: 8px;
  margin-bottom: 29px;
}

.footerSection {
  bottom: 10px;
  transform: translateY(78px);
  visibility: hidden;
  margin-bottom: 0;
}
.initFooter {
  transform: translateY(0px);
  visibility: visible;
}

.hideHeader,
.hideFooter {
  opacity: 0;
  transition:
    transform var(--animate-duration),
    opacity 0s ease-out var(--animate-duration);
}

.hideHeader {
  transform: translateY(-78px);
}

.hideFooter {
  transform: translateY(78px);
}
