.titleFaq {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: var(--font-family-secondary), serif;
}

.header {
  grid-gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    display: block;
  }
}

.accordion {
  text-align: left;
}

.accordionTitle {
  flex-grow: 2;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

.question {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-foreground-lighter-4);
  border-radius: 16px;
  background: var(--color-white);
  padding: 24px 20px;
  width: 100%;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    transition: all 0.2s ease-in-out;
  }
}

.classNameOpened {
  svg {
    transform: rotate(180deg);
  }
}

.iconTransform {
  svg {
    transform: rotate(180deg);
  }
}
