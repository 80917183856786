.item {
  box-shadow: inset 0 0 0 1px #e7e4de;
  border-radius: 24px;
  text-align: center;
}

.title {
  margin-top: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.description {
  margin: 8px auto 16px;
  max-width: 290px;
}
