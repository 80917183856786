.policy {
  margin-top: 20px;
  margin-bottom: 16px;
  color: var(--color-pastel);
  font-size: 12px;
  line-height: 133.333%;
  text-align: center;
}

.link {
  text-decoration: underline;
}
