$font-family-secondary: 'Merriweather', serif;

$color-black: #000000;
$color-white: #ffffff;
$color-pastel: #817b6f;
$color-foreground-darker-2: #0e0e0d;
$color-foreground-darker-1: #211f1e;
$color-foreground: #302c28;
$color-foreground-lighter-1: #443f3a;
$color-foreground-lighter-2: #737377;
$color-foreground-lighter-3: #959085;
$color-foreground-lighter-4: #c8c4bb;
$color-background-darker: #e7e4de;
$color-background: #efede8;
$color-background-lighter-1: #f6f4f0;
$color-background-lighter-2: #f9f8f7;
$color-background-lighter-3: #e6f1ef;
$color-accent-1: #ffb800;
$color-accent-1-background: #ffdc81;
$color-accent-2: #356b61;
$color-accent-2-background: #edf6f0;
$color-red: #ed827b;
$color-green: #356b61;
$local-grey: #8d8d9c;

$header-height: 72px;
