.container {
  top: auto;
  bottom: 0;
  min-height: calc(100% - 24px);
  max-height: calc(100% - 24px);

  :global(.modal-content) {
    border-radius: 24px 24px 0 0 !important;
    padding: 32px 16px 16px 16px;
    height: 100%;
  }
}

.hidePayments {
  display: none;
}

.content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: 0 -16px;
  padding: 0 16px;
  overflow-y: scroll;
}

.title {
  color: var(--color-foreground);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  font-family: var(--font-family-secondary), serif;
  letter-spacing: 0.2px;
  text-align: center;
}

.loaderContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #8d8d9c;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.loader {
  animation: loader linear 2s infinite;
  margin-bottom: 22px;
}

.modalTitle {
  color: var(--color-foreground);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
}

.disclaimer {
  flex: 1;
  margin-bottom: 8px;
  color: var(--color-foreground-lighter-4);
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
}

.hideLoading {
  display: none;
}

.backdrop {
  backdrop-filter: none;
  background: rgba(75, 75, 75, 0.8);
}

.line {
  position: absolute;
  top: 8px;
  opacity: 1;
  margin: 0;
  border-top: 4px solid;
  border-radius: 5px;
  width: 43px;
  color: #d9d9d9;
}

.headerCloseButton {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 36px;
  height: 36px;
  color: var(--color-pastel);
}

.placeholder {
  width: 100%;
  min-height: 350px;
}

.paymentButtonsContainer {
  flex: 1 1;
  margin-top: 8px;
}

.paymentButton {
  margin: 0 auto 8px auto;
  border: 2px solid var(--color-foreground-lighter-4);
  width: 327px;
  height: 60px;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    border: 2px solid var(--color-green);
  }
}

.googlePayButton {
  border: none;
  background-color: #000 !important;
}

.paypalButton {
  border: none;
  background-color: #ffc439 !important;
}

.debitCardButton {
  border: none;
  background-color: #f5f4f1 !important;
}

.logosContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin: 4px 0;
  height: 20px;
}

.paypalLogo {
  width: 21.46px;
  height: 22px;
}

.visaLogo {
  width: 49px;
  height: 16px;
}

.mastercardLogo {
  width: 26px;
  height: 20px;
}

.amexLogo {
  width: 28px;
  height: 20px;
}

.unionpayLogo {
  width: 31px;
  height: 20px;
}

.lockIcon {
  width: 12px;
  height: 12px;
}

.footer {
  p:first-child {
    color: var(--color-foreground);
    font-weight: 700;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
  }
  p:last-child {
    display: flex;
    align-items: center;
    margin-left: 4.8px;
    color: var(--color-pastel);
    font-weight: 400;
    font-size: 12px;
    line-height: 167%;
    text-align: center;
  }
}

.loaderContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--local-grey);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.loader {
  animation: loader linear 2s infinite;
  margin-bottom: 22px;
}

.hidePayments {
  display: none;
}

@keyframes loader {
  0% {
    transform: rotate(-360deg);
  }
}

@media (min-width: 575.98px) {
  .container {
    min-width: 400px;
    min-height: 500px;

    :global(.modal-content) {
      border-radius: 24px !important;
      padding-bottom: 16px;
      min-width: 400px;
      min-height: 500px;
    }
    :global(.modal-dialog) {
      min-width: 400px;
    }
  }
}
