.cardBlockContainer {
  margin-top: 20px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 16px;
  text-align: left;
}

.minimal {
  margin-top: 0;
  box-shadow: none;
  padding: 0;
  .cardInfo {
    font-size: 14px;
  }
}

.cardInfo {
  padding: 0 8px;
  color: var(--color-foreground);
  line-height: 142.857%;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 4px;
  border: 1px solid var(--color-background-darker);
  border-radius: 16px;
  background: var(--pastel-gray-4, #f5f4f1);
  overflow: hidden;
}

.cardElement,
.nameElement,
.expiredAndCvc {
  height: 48px;
}

.cardElement,
.nameElement {
  padding: 13px 20px;
  width: 100%;
}

.cardElement,
.nameElement {
  border-bottom: 1px solid var(--color-background-darker);
}

.nameElement {
  text-align: left;
}

.nameInput {
  border: none;
  background-color: transparent;
  padding: 0;
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    border: none;
  }
  &::placeholder {
    color: var(--color-pastel);
  }
}

.expiredAndCvc {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.expiredElement {
  border-right: 1px solid var(--color-background-darker);
}

.expiredElement,
.cvcElement {
  padding: 13px 20px;
  width: 50%;
}

.message {
  display: block;
  margin: 10px auto 0 auto;
  color: #ff2e00;
  font-size: 14px;
  line-height: 142.857%;
  text-align: center;
}

.proceed {
  margin: 10px auto 0 auto;
  width: 100%;
  max-width: none;
}
