.logosContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin: 10px 0;
  height: 18px;
  grid-gap: 28.8px;
}

.paypalLogo {
  width: 21.46px;
  height: 22px;
}

.visaLogo {
  width: 49px;
  height: 16px;
}

.mastercardLogo {
  width: 26px;
  height: 20px;
}

.amexLogo {
  width: 28px;
  height: 20px;
}

.unionpayLogo {
  width: 31px;
  height: 20px;
}

.lockIcon {
  width: 12px;
  height: 12px;
}

.debitCreditBlock {
  p:first-child {
    color: var(--color-foreground);
    font-weight: 700;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
  }
  p:last-child {
    display: flex;
    align-items: center;
    margin-left: 4.8px;
    color: var(--color-pastel);
    font-weight: 400;
    font-size: 12px;
    line-height: 167%;
    text-align: center;
  }
}
