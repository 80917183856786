.sliderContainer {
  :global(.slick-dots) {
    bottom: -28px;
  }

  :global(.slick-dots li button:before) {
    border-radius: 8px;
    background: var(--pastel-700, #c8c4bb);
    width: 6px;
    height: 4px;
    content: '';
  }

  :global(.slick-dots li) {
    margin: 0;
  }

  :global(.slick-dots li.slick-active button:before) {
    background: var(--primary-100, #302c28);
    width: 12px;
  }
}
