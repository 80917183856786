@use '../../../../styles/theme';

.container {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    border-radius: 12px;
    background-color: theme.$color-white;
    max-width: none;
    color: theme.$color-foreground;
    font-weight: 700;
  }
}
