.subscriptionAgreement {
  display: flex;
  align-items: flex-start;
  margin: 24px 0;
  color: var(--color-foreground);
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;

  input {
    width: 24px;
    height: 24px;
  }

  label {
    margin-left: 16px;
    text-align: left;
  }

  a {
    text-decoration: underline;
  }
}

.dangerAgreement {
  color: #ed102a;
}
