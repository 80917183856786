@use './theme';
@use './functions';

$primary: theme.$color-foreground;
$secondary: theme.$color-white;
$success: theme.$color-accent-2;
$danger: theme.$color-red;
$light: theme.$color-background;
$dark: theme.$color-foreground;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'accent-1': theme.$color-accent-1,
  'accent-2': theme.$color-accent-2,
);

$font-family-base: 'Inter', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.4;
$body-bg: theme.$color-background;

$container-padding-x: 1.5rem;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
);
$container-max-widths: (
  sm: 540px,
);

$input-padding-x: 20px;
$input-padding-y: 24px;
$input-font-size: 16px;
$input-line-height: 1.4;
$input-color: theme.$color-foreground;
$input-bg: inherit;
$input-border-color: theme.$color-foreground-lighter-4;
$input-border-radius: 16px;
$input-placeholder-color: theme.$color-foreground-lighter-3;
$input-disabled-bg: theme.$color-background-darker;
$input-disabled-border-color: theme.$color-background-darker;

$input-padding-x-sm: 8px;
$input-padding-y-sm: 8px;
$input-border-radius-sm: 8px;

$enable-validation-icons: false;
$form-feedback-margin-top: 8px;
$form-feedback-font-size: 14px;
$form-feedback-invalid-color: theme.$color-red;
$form-feedback-valid-color: theme.$color-green;

$form-text-font-size: 14px;
$form-text-color: theme.$color-foreground-lighter-3;

$form-check-margin-bottom: 0;
$form-check-input-width: 1.25em;
$form-check-input-border-radius: 6px;
$form-check-input-bg: theme.$color-background;
$form-check-input-border: none;
$form-check-input-checked-bg-color: theme.$color-accent-2;
$form-check-input-checked-bg-image: functions.inline-svg(
  '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 8.96179L11.2336 16.1973C10.8561 16.6009 10.244 16.6009 9.86654 16.1973L6 12.0627L7.36703 10.6009L10.55 14.0046L16.633 7.5L18 8.96179Z" fill="#{theme.$color-white}"/></svg>'
);

$carousel-indicator-width: 6px;
$carousel-indicator-height: 4px;
$carousel-indicator-hit-area-height: 0;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: 1;
$carousel-indicator-active-bg: theme.$color-foreground;
$carousel-caption-width: 100%;
$carousel-caption-color: inherit;
$carousel-caption-padding-y: 0;
$carousel-caption-spacer: 0;
$carousel-transition-duration: 0.5s;

$enable-caret: false;

$accordion-bg: transparent;
$accordion-border-width: 0;
$accordion-border-radius: 16px;
$accordion-button-padding-x: 16px;
$accordion-button-padding-y: 16px;
$accordion-icon-width: 40px;
$accordion-button-icon: functions.inline-svg(
  '<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><path d="M14.6667 17.3333L20.0001 22.6666L25.3334 17.3333" fill="none" stroke="#{theme.$color-foreground}" stroke-width="2.66667" stroke-linecap="square"/></svg>'
);
$accordion-button-active-icon: $accordion-button-icon;
$accordion-body-padding-x: 16px;
$accordion-body-padding-y: 16px;
