.learnMoreList {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  grid-gap: 8px;
}

.learnMoreListCompact {
  border-radius: 8px;
  padding: 8px;
  grid-gap: 5px;
  border: 1px solid var(--Outline-outline-tertiary, #e7e4de);
}

.learnMoreItem {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  text-align: left;

  img {
    display: block;
  }
}

.learnMoreItemCompact {
  font-size: 16px;
}
