.priceUnitFull {
  color: var(--color-foreground-lighter-4);
  font-size: var(--mg-paywall-card-price-unit-full-fs);
  line-height: var(--mg-paywall-card-price-unit-full-lh);
  white-space: nowrap;
}

.priceUnitFullChecked {
  color: var(--color-pastel);
}
