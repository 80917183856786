.container {
  display: flex;
  position: relative;
  align-items: center;
  align-self: center;
  margin: 16px auto;
  width: 100%;
  max-width: 327px;
  grid-gap: 16px;
}

.guarantee {
  color: var(--color-foreground);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-family-base), serif;
  text-align: center;

  mark {
    color: var(--color-accent-2);
    font-style: normal;
  }
}
