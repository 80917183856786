.issues {
  margin-bottom: 16px;
  font-weight: 800;
  font-size: 20px;
  font-family: var(--font-family-secondary), serif;
  text-align: center;
}

.buttonContact {
  align-self: center;
  width: 100%;
  max-width: 327px;
  --mg-color-primary: var(--color-foreground);
  --mg-bg-button-hover: var(--color-foreground);
}
