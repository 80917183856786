.titleBlock {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  font-family: var(--font-family-secondary), serif;
}
.description {
  margin-bottom: 24px;
  color: var(--color-foreground);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.imageWrapper {
  position: relative;
  margin: 0 -24px 16px -24px;
  aspect-ratio: 1.33;
  width: calc(100% + 48px);
  height: 100%;
  min-height: 280px;
  max-height: none;
}

.video {
  margin: 0 -24px 0 -24px;
  width: calc(100% + 48px);
  height: 100%;
  min-height: 250px;
  max-height: none;
}

.startBlockSection {
  align-items: center;
}
