.currentPrice {
  display: flex;
  align-items: flex-start;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;

  & > span:first-child {
    font-size: 20px;

    .currentPriceIsLongCurrencies & {
      font-size: 16px;
    }
  }

  span:nth-child(2) {
    margin-right: 1px;
    font-weight: 800;
    font-size: var(--mg-paywall-card-current-price-large-fs);
    line-height: var(--mg-paywall-card-current-price-large-lh);
  }
}

.perTerm {
  color: var(--color-foreground-lighter-4);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  white-space: nowrap;
}

.perPeriod {
  text-transform: uppercase;
}

.currentPriceChecked {
  .perTerm,
  .perPeriod {
    color: var(--color-foreground);
  }
}

.currentPriceIsLongCurrencies {
  --mg-paywall-card-title-float-fs: 16px;
  --mg-paywall-card-title-float-lh: 22px;
  --mg-paywall-card-current-price-large-fs: 36px;
  --mg-paywall-card-current-price-large-lh: 34px;
}
