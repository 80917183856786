.result {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: var(--color-background-lighter-1);
  padding: 24px 24px 63px;

  h3 {
    align-self: flex-start;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    font-family: var(--font-family-secondary), serif;
    letter-spacing: 0.2px;
    text-align: left;
  }

  p {
    color: var(--color-pastel);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    text-align: left;
  }

  > :last-child {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}
