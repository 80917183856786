@use './theme';
@use './bootstrap';

.carousel-indicators {
  [data-bs-target] {
    transition:
      width bootstrap.$carousel-transition-duration,
      background-color bootstrap.$carousel-transition-duration;
    margin: 0 4px;
    border-radius: 8px;
    background-color: theme.$color-foreground-lighter-4;

    &.active {
      background-color: theme.$color-foreground;
      width: 12px;
    }
  }
}

.accordion-item:not(:last-child) {
  &::after {
    display: block;
    margin: 0 bootstrap.$accordion-button-padding-x;
    border-bottom: 1px solid theme.$color-background-lighter-1;
    content: '';
  }
}

.accordion-button {
  justify-content: space-between;
  font-weight: bold;

  &:not(.collapsed) {
    background-color: transparent;
  }

  &::after {
    margin-left: bootstrap.$accordion-button-padding-x;
  }
}
