.wrapper {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.wrapperDisabled {
  & * {
    color: var(--color-foreground-lighter-4) !important;
  }
}

.wrapperInvisible {
  display: none;
}

.planCard {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: inset 0 0 1px 1px var(--color-foreground-lighter-4);
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  color: var(--color-foreground-lighter-4);

  & h4:first-child {
    color: var(--color-pastel);
  }
}

.input {
  position: absolute;
  opacity: 0;

  &:checked + .planCard {
    box-shadow: var(--checked-box-shadow);
    color: var(--color-foreground);

    h4 {
      color: var(--color-foreground);
    }
  }
}

.checked + .planCard {
  box-shadow: inset 0 0 0 2px var(--color-accent-2);
  color: var(--color-foreground) !important;
}

.planCardDisabled {
  border: 1px solid var(--color-background-lighter-1);
}

.titleWrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-self: center;
  filter: var(--sc-title-filter);
  background: var(--color-foreground-lighter-4);
  width: 100%;
  height: 28px;
  color: var(--sc-title-font-color);
}

.titleWrapperChecked {
  filter: var(--sc-title-filter-checked);
  background: var(--sc-title-background-checked);
  color: var(--sc-title-font-color-checked);
}

.titleCard {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
}

.leftBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  grid-gap: 0;

  h4 {
    width: 100%;
    font-weight: 700;
    font-size: var(--mg-paywall-card-description-fs);
    line-height: var(--mg-paywall-card-description-lh);
    text-align: left;
  }

  del {
    margin-right: 4px;
    padding: 2px 4px;
    font-weight: var(--mg-paywall-card-default-discount-fw);
    font-size: var(--mg-paywall-card-default-discount-fs);
    line-height: var(--mg-paywall-card-default-discount-lh);
  }
}

.presentContainer {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  grid-gap: 4px;
  position: relative;
  z-index: 1;
  border: 1px dashed var(--pastel-700, #c8c4bb);
  border-radius: 12px;
  background: #f0eee8;
}

.rightBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > div {
    display: flex;
    align-items: center;
  }

  h4 {
    font-weight: 700;
    font-size: var(--mg-paywall-card-title-float-fs);
    line-height: var(--mg-paywall-card-title-float-lh);
  }
}

.rightBlockTop {
  grid-gap: 4px;
}

.content {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px 16px 16px;
  width: 100%;
  min-height: 85px;
}

.price {
  display: flex;
  font-weight: 400;
  font-size: 14px;
}

.discountCurrency {
  color: #f8574c;
  font-weight: 700;
}

.wrapperGreen {
  .planCard {
    position: relative;
    border-radius: 16px 16px 0 0;

    &::after {
      position: absolute;
      right: 8px;
      bottom: -4px;
      left: 8px;
      background: url('../../../../../assets/icons/circle.svg');
      height: 8px;
      content: '';
    }
  }

  .content {
    border-radius: 0;
    background: var(--color-accent-2);

    .leftBlock h4,
    div,
    span {
      color: white;
    }

    .rightBlock del,
    p {
      color: var(--pastel-300, #f6f4f0) !important;
    }
  }
}
