.selectorTabs {
  display: flex;
  grid-gap: 8px;
}

.paymentOptionTab {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  filter: grayscale(1);
  margin-top: 24px;
  border: 2px solid transparent;
  border-radius: 8px;
  background: var(--color-background-lighter-1);
  padding: 8px 4px;
  width: 100%;
  min-height: 48px;

  &:only-child {
    display: none;
  }
}

.paymentOptionTabSelected {
  filter: grayscale(0);
  border: 2px solid var(--color-accent-2);
  border-radius: 8px;
}

.paymentOptionSubmitButton {
  position: relative;
  margin-top: 24px;
}

.buttonWrapper {
  position: relative;
  opacity: 0;
  z-index: 2;
}

.buttonCoverage {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 16px;
  background-color: #000;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}
