.container {
  :global(.modal-content) {
    border-radius: 0;
    background: #fff;
    padding: 4px 16px 24px 16px;
    height: 100%;
    overflow-y: scroll;
    text-align: center;
  }
}

.widthWrapper {
  max-width: 100%;
}

.title {
  margin: 24px 0 12px;
  color: var(--color-foreground);
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  font-family: var(--font-family-secondary), serif;
  letter-spacing: -0.28px;
  text-align: center;

  .mark {
    color: var(--color-accent-2);
    font-style: normal;
  }
}

.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 40px 0 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: var(--font-family-secondary), serif;
  text-align: center;
}

.offMark {
  border-radius: 8px;
  background: #356b61;
  padding: 4px 12px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-family-base), serif;
  text-transform: uppercase;
}

.payButton {
  margin-top: 24px;
}

.declineButton,
.declineButton:hover {
  padding: 8px 40px;
  height: 28px;
  color: var(--color-pastel);
  text-decoration: underline;
  --mg-button-fs: var(--mg-body2-fs);
}

.declineButton {
  margin: 12px auto 0 !important;
}

.cupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  border-radius: 12px;
  background: #feeedb;
  padding: 4px 16px 4px 30px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  &:before {
    margin-right: 8px;
    background-image: url('../../../../../public/images/upsell-cup-0.svg');
    width: 26px;
    height: 40px;
    content: '';
  }
}

.surchargePerDay {
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.24px;

  &::first-letter {
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  sup {
    top: -20px;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.24px;
  }
}

.sign {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.2px;
}

.currency {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.priceBlock {
  display: inline-flex;
  position: relative;
  align-items: center;
}

.priceBlockSubTitle {
  margin-bottom: 8px;
}

.period {
  position: absolute;
  right: -16px;
  bottom: 6px;
  color: #302c28;
  font-size: 12px;
  line-height: 17px;
}

.additionalDescription {
  margin-top: 16px;
  color: #817b6f;
  font-size: 12px;
  line-height: 17px;

  p:not(:first-child) {
    margin-top: 8px;
  }
}

.additionalCharge {
  font-size: 10px;
  line-height: 14px;
}

.additionalContrast {
  color: #302c28;
}

@media (min-width: 576px) {
  .container {
    :global(.modal-content) {
      border-radius: 16px;
    }
  }
}
