.feelYoungerSection {
  position: relative;
  z-index: 1;

  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 136%;
    font-family: var(--font-family-secondary), serif;
  }
  mark {
    color: var(--color-accent-2);
    font-style: normal;
  }
}

.videoReviewsContainer {
  margin-top: 24px;
}
