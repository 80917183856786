@use '../../../styles/theme';
@use '../../../styles/bootstrap';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 16px;
  padding: 0 20px;
  width: 100%;
  max-width: 327px;
  height: 60px;
  font-weight: bold;
  font-size: 16px;
}

.icon {
  margin-right: 10px;
}

.containerPrimary {
  &,
  &:hover,
  &:focus {
    background-color: theme.$color-foreground;
  }

  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: theme.$color-white;
  }

  &:active {
    background-color: theme.$color-foreground-lighter-1;
  }

  @media (hover: hover) {
    &:hover {
      background-color: theme.$color-foreground-darker-1;
      color: theme.$color-white;
    }
  }

  &:disabled {
    opacity: 1;
    background-color: theme.$color-foreground-lighter-4;
    color: fade-out(theme.$color-white, 0.5);
  }
}

.containerSecondary {
  font-weight: normal;

  &,
  &:active,
  &:hover,
  &:focus {
    background-color: theme.$color-white;
  }

  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: theme.$color-foreground;
  }

  @media (hover: hover) {
    &:hover {
      background-color: theme.$color-background-lighter-2;
      color: theme.$color-foreground;
    }
  }

  &:disabled {
    opacity: 1;
    background-color: mix(theme.$color-white, theme.$color-background, 65%);
    color: fade-out(theme.$color-foreground, 0.35);
  }
}

.accent2 {
  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    background-color: theme.$color-accent-2;
    color: theme.$color-white;
  }

  @media (hover: hover) {
    &:hover {
      background-color: theme.$color-accent-2;
      color: theme.$color-white;
    }
  }

  &:disabled {
    opacity: 1;
    background-color: #a7beba;
    color: theme.$color-white;
  }
}

.link {
  &,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    padding: 0 24px;
    width: 186px;
    height: 44px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration: underline;
    white-space: nowrap;
  }
}

.flat {
  color: theme.$color-red;
}

.outline {
  box-shadow: 0 0 0 1px theme.$color-accent-2;
}

.sm {
  width: auto;
  height: 24px;
}

.lg {
  height: 72px;
}
