@use '../../../../styles/utils';
@include utils.pulse-button-animation(#356b61);

$headerCard: 24px;

.titleSection {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 28px;
  line-height: 39.2px;
  font-family: var(--font-family-secondary), serif;
}

.backToPlansButton {
  margin: 18px 0;
  svg {
    width: 20px;
    height: 20px;
  }
}

.listPlans {
  width: 100%;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  width: 100%;
}

.paymentButton {
  animation: pulse-button 2s infinite;
  &,
  &:active,
  &:focus,
  &:visited,
  &:hover {
    align-self: center;
    max-width: 327px;
  }
}

.trialDisclaimerHasTrial {
  margin-top: -8px;
}
