.host {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  mix-blend-mode: luminosity;
  border-radius: 8px;
  background: linear-gradient(
    90deg,
    #c8966d,
    #c3926a,
    #be8f68,
    #ba8b65,
    #b58863,
    #b08460,
    #ac815e,
    #a77d5b
  );
  padding: 8px;
  color: #fff;
  font-size: 14px;
}

.select {
  border-radius: 4px;
}

.expanded {
  width: 100%;
}

.row {
  display: flex;
  grid-gap: 8px;
  overflow: auto;
}

.list {
  margin: 8px;
  max-height: 300px;
  overflow: auto;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}
