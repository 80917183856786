.container,
.email2Container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: -32px -24px 0 -24px;
  background: url('../../../../../public/images/paywall-error-decline-first-block-background.webp')
    no-repeat center center / cover;
  padding: 32px 24px 16px 24px;
  aspect-ratio: 375/482;
  width: calc(100% + 48px) !important;
  max-height: 500px;
}

.email2Container {
  position: relative;
  background: url('../../../../../public/images/email-paywall-background.webp')
    lightgray 50% / cover no-repeat;
  max-height: 482px;
}

.imageMask {
  position: absolute;
  top: 150.7px;
  left: 50%;
  transform: translateX(-50%);
}

.priceBanner {
  position: relative;
  border-radius: 16px;
  background: var(--color-white);
  padding: 8px;
  width: 327px;
  font-size: 23.729px;
  del {
    color: var(--color-pastel);
  }
}

.bannerDescription {
  margin-top: 8px;
  font-size: 18px;
}

.bannerLabel {
  display: flex;
  position: absolute;
  top: -19px;
  right: -6px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffd675;
  width: 32px;
  height: 32px;
  content: '';
}

.timerContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  align-items: center;
  backdrop-filter: blur(1.0967741012573242px);
  border-radius: 16px;
  background: #ffd675;
  padding: 12px 16px;
  width: 327px;
}

.timerDescription {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4.71px;
}

.timerTime,
.timerDivider {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--color-white);
  padding: 8px;
  width: 35px;
  height: 48px;
  font-weight: 700;
  font-size: 28px;
  line-height: 135.714%;
  font-family: var(--font-family-secondary), serif;
}

.timerDivider {
  background: transparent;
}
