.container {
  :global(.modal-content) {
    border-radius: 0;
    background: var(--color-background);
    padding: 4px 16px 24px 16px;
    height: 100%;
    overflow-y: scroll;
    text-align: center;
  }
}

.fullviewportContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageWrapper {
  position: relative;
  flex: 1 1;
  width: 100%;
  height: 100%;
}

.title {
  margin-top: 24px;
  color: var(--color-foreground);
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  font-family: var(--font-family-secondary);
  letter-spacing: 0.28px;
  text-align: center;
  & > mark {
    color: var(--color-accent-2);
    font-style: normal;
  }
}

.ebookFeatures {
  display: flex;
  flex-direction: column;
  text-align: left;
  grid-gap: 8px;
  margin-top: 16px;

  & > li {
    display: flex;
  }

  & > li::before {
    display: block;
    margin-right: 5px;
    height: 100%;
    content: '•';
  }
}

.price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  grid-gap: 4px;
  & > del {
    color: var(--color-pastel);
    line-height: 140%;
  }
  & > b {
    font-size: 20px;
    font-family: var(--font-family-secondary);
  }
}

.payButton {
  margin-top: 24px;
}

.declineButton,
.declineButton:hover {
  --mg-color-button-bg-pure: var(--color-background);
  margin-top: 12px;
  padding: 8px 40px;
  height: 28px;
  color: var(--color-pastel);
  text-decoration: underline;
  --mg-button-fs: var(--mg-body2-fs);
}

.underScrollText,
.policyFirstLine {
  color: var(--color-pastel);
  font-size: 12px;
  line-height: 133.333%;
}

.policyFirstLine {
  margin-top: 8px;
}

.refundPolicy {
  text-align: center;
}

@media (min-width: 576px) {
  .container {
    :global(.modal-content) {
      border-radius: 16px;
    }
  }
}
