.discountPercentage {
  margin: 0;
  border-radius: 4px;
  padding: 2px 4px;
  font-weight: 700;
}

.discountPercentageDefault {
  background: var(--color-foreground-lighter-4);
  color: var(--color-white);
}

.discountPercentageActive {
  background: var(--sc-discount-percantage-active-bg);
  color: var(--color-white);
}

.discountPercentageActiveYellow {
  background: var(--color-accent-1);
  color: var(--color-white);
}

.outline {
  box-shadow: inset 0 0 0 1.5px var(--color-accent-1);
}

.outlineDisabled {
  box-shadow: inset 0 0 0 1.5px var(--color-background-darker);
  color: var(--color-white);
}

.medium {
  font-size: var(--mg-paywall-card-discount-percent-fs);
  line-height: 20px;
}

.small {
  font-size: 11px;
}

.withText {
  background: #f65668;
  color: var(--color-white);
}

.withTextDisabled {
  background: #817b6f;
  color: var(--color-white);
}
