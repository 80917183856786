.review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background-color: var(--color-background-lighter-1);
  padding: 16px;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}
.headerReview {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;

  > :last-child {
    align-self: flex-start;
    margin-left: auto;
    font-weight: 400;
    font-size: 15px;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
  }

  h5 {
    color: var(--color-pastel);
  }
}

.starsList {
  display: flex;
  grid-gap: 8px;
  li {
    margin: 0;
  }
}

.reviewCardAvatarContainer {
  margin-right: 15px;

  img {
    display: block;
    border-radius: 50px;
    background-color: var(--color-black);
    width: 48px;
    height: 48px;
  }
}
