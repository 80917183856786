@use '../../../../styles/bootstrap.scss';
@use '../../../../styles/theme.scss';

.title {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  font-family: var(--font-family-secondary), serif;
}

.description {
  margin-bottom: 24px;
}
.slider {
  display: flex;
  justify-content: center;
  width: 100%;
}

.photosList {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  transform: translateX(-24px);
  z-index: 1;
  margin-bottom: 16px;
  width: calc(100% + 48px);
  overflow-x: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.photoItem {
  flex-direction: column;
  border: 1px solid theme.$color-background-darker;
  border-radius: 16px;
  background: theme.$color-white;
  width: 88%;
  min-width: 88%;

  overflow: hidden;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:first-child {
    margin-left: 24px;
  }

  &:last-child {
    margin-right: 24px;
  }
}

.photo {
  display: block;
  position: relative;
  aspect-ratio: 1.22;
}

.headerPhoto {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50px;
  color: theme.$color-pastel;
}
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  grid-gap: 5px;
}

.paginationButton {
  transition: all 0.2s;
  border-radius: 8px;
  background: theme.$color-foreground-lighter-4;
  width: 6px;
  height: 4px;
}
.paginationButtonActive {
  transform: scaleX(1.8);
  margin: 0 3px;
  background: theme.$color-foreground;
}
