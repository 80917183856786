@use './theme';

@import './bootstrap';

@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/accordion';

@import './bootstrap-global';

html {
  min-height: 100%;
}

body,
#__next {
  flex: 1;
}

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: theme.$color-white;
}

:root {
  color: theme.$color-foreground;
  color-scheme: only light;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-family: var(--font-family-base), serif;
}

:root {
  --animate-duration: 0.2s;
  --primary-color: #356b61;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-pastel: #817b6f;
  --color-foreground-darker-2: #0e0e0d;
  --color-foreground-darker-1: #211f1e;
  --color-foreground: #302c28;
  --color-foreground-lighter-1: #443f3a;
  --color-foreground-lighter-2: #737377;
  --color-foreground-lighter-3: #959085;
  --color-foreground-lighter-4: #c8c4bb;
  --color-background-darker: #e7e4de;
  --color-background: #efede8;
  --color-background-lighter-1: #f6f4f0;
  --color-background-lighter-2: #f9f8f7;
  --color-background-lighter-3: #e6f1ef;
  --color-accent-1: #ffb800;
  --color-accent-1-background: #ffdc81;
  --color-accent-2: #356b61;
  --color-accent-2-rgb: 53, 107, 97;
  --color-accent-2-background: #edf6f0;
  --color-red: #ed827b;
  --color-light-red-secondary: #faecea;
  --color-green: #356b61;
  --local-grey: #8d8d9c;
  --local-beige: #fcf3e4;
  --color-light-green: #83a79a;
  --color-light-beige: #fff1cc;
  --color-light-white: #d9d9d9;
  --color-pastel-green: #cef1dc;
  --header-height: 72px;
  --color-accent-red: #d82929;
  --color-background-red: #ffebeb;
}

a {
  text-decoration: none;

  &,
  &:visited,
  &:hover {
    color: inherit;
  }
}

a,
button,
input,
select,
textarea {
  touch-action: manipulation;
}

a,
button,
label {
  cursor: pointer;
  user-select: none;
}

button {
  border: none;
  background: none;
  padding: 0;
  overflow: visible;
  color: inherit;
  text-align: left;

  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--mg-primary-ff), serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
ul,
ol {
  margin: 0;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

@include media-breakpoint-down(sm) {
  #__next {
    transition: transform 0.3s ease-in-out;
  }
  body[data-animated-modals-open]:not([data-animated-modals-open='0']) #__next {
    transform: translateX(-25%);
  }
}

.highlight {
  color: var(--primary-color);
}

.double-grow {
  flex-grow: 2;
  width: 100%;
}
