.slider {
  display: flex;
  justify-content: center;
  width: 100%;
}

.photosList {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  transform: translateX(-24px);
  z-index: 1;
  margin-bottom: 16px;
  width: calc(100% + 48px);
  overflow-x: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.photoItem {
  flex-direction: column;
  background: var(--color-white);
  overflow: hidden;

  &:first-child {
    margin-left: 24px;
  }

  &:last-child {
    margin-right: 24px;
  }
}

.photo {
  display: block;
  position: relative;
  aspect-ratio: 1.22;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  grid-gap: 5px;
}

.paginationButton {
  transition: all 0.2s;
  border-radius: 8px;
  background: var(--color-foreground-lighter-4);
  width: 6px;
  height: 4px;
}
.paginationButtonActive {
  transform: scaleX(1.8);
  margin: 0 3px;
  background: var(--color-foreground);
}
