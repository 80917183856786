@use '../../../../styles/theme.scss';

.title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: var(--font-family-secondary), serif;

  em {
    color: theme.$color-accent-2;
    font-style: normal;
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background-color: theme.$color-background-lighter-1;
  padding: 16px;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
  }
}
.reviewItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.titleReview {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: var(--font-family-secondary), serif;

  em {
    color: var(--color-accent-2);
    font-style: normal;
  }
}
