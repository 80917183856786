@use '../../../../styles/theme';

.planDescription {
  margin-top: 12px;
  color: theme.$color-foreground-lighter-3;
  font-size: 14px;
  text-align: center;
}

.submitButtonIcon {
  margin-right: 10px;

  svg {
    display: block;
  }
}

.planPriceBlock,
.planDiscountBlock,
.totalBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: theme.$color-pastel;
  font-size: 14px;
}

.currentPlanInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: -8px;
}

.planPriceBlock {
  margin-bottom: 8px;
  span:last-child,
  del {
    color: theme.$color-pastel;
    font-weight: 700;
    line-height: 143%;
  }
}

.planDiscountBlock {
  span:last-child {
    color: theme.$color-accent-2;
    font-weight: 700;
    line-height: 143%;
  }
}

.currentPlanInfoLine {
  opacity: 1;
  margin: 8px 0;
  color: #e7e4de;
}

.totalBlock {
  em {
    color: theme.$color-foreground;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 143%;
  }
}
