.wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}

.video {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progressRing {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  pointer-events: none;
}

.progressBackground {
  fill: none;
  stroke: transparent;
}

.progressBar {
  fill: none;
  stroke: var(--color-white);
  border-radius: 20px;
}

.playIcon {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  cursor: pointer;
  pointer-events: all;
  color: var(--color-white);
}

.soundIcon {
  display: flex;
  position: absolute;
  right: 50%;
  bottom: 16px;
  left: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transform: translate(-50%);
  backdrop-filter: blur(8px);
  cursor: pointer;
  border-radius: 100px;
  background: rgba(46, 45, 43, 0.24);
  width: 24px;
  height: 24px;
  pointer-events: all;
  color: var(--color-white);
}
