@mixin position-sticky {
  position: -webkit-sticky;
  @supports (position: sticky) {
    position: sticky;
  }
}

@mixin replace-backdrop-color($color, $transition-duration) {
  // Once the fade transition completes, setting the background to white.
  // This way, the backdrop blends with the modal background and is not visible during
  // scroll overshoot animation in iOS Safari.
  &:global(.show) {
    animation: force-backdrop-color 0s $transition-duration forwards;
  }
  @keyframes force-backdrop-color {
    100% {
      background-color: $color;
    }
  }
}

@mixin cover-area-under-modal($color) {
  // Covering the area underneath the iOS Safari address bar with a solid color,
  // so the content under the modal is not visible through the frosted glass effect
  &::after {
    position: fixed;
    top: min(100%, 100vh);
    right: 0;
    left: 0;
    background: $color;
    height: 100vh;
    content: '';
  }
}

@mixin pulse-button-animation($color) {
  @keyframes pulse-button {
    0% {
      box-shadow: 0 0 0 0 rgb($color, 0.7);
    }
    50% {
      box-shadow: 0 0 0 10px rgb($color, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgb($color, 0);
    }
  }
}

@mixin graph-reveal-animation($animationName, $max-width) {
  @keyframes #{$animationName} {
    50% {
      max-width: $max-width;
    }
    100% {
      max-width: $max-width;
      overflow: unset;
    }
  }
}

@mixin safe-area-aware-padding-bottom($value) {
  $safe-area-aware-value: max(#{$value}, env(safe-area-inset-bottom));
  @supports (padding-bottom: $safe-area-aware-value) {
    padding-bottom: $safe-area-aware-value;
  }
}
