.host {
  margin-top: 24px;
  border-radius: var(--rounded-radius-8);
  background-color: var(--mg-color-primary-container);
  padding: 12px;
  color: var(--mg-color-on-surface-variant);
  font-size: var(--mg-body3-fs);
  line-height: var(--mg-lh-140);
  text-align: left;

  p {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
