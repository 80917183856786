.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
}

.paymentButtonsContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  & > * {
    width: 49%;
  }
  & > *:only-child {
    width: 100%;
  }
}
