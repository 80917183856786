@use '../../../../styles/bootstrap.scss';
@use '../../../../styles/utils';
@include utils.pulse-button-animation(var(--color-accent-2-rgb));

.titleWrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-self: center;
  width: 100%;
  height: 28px;
}

.planCard {
  --sc-title-background-checked: var(--color-accent-2);
  --checked-box-shadow: inset 0 0 0 2px var(--color-accent-2);
  --sc-discount-percantage-active-bg: var(--color-accent-2);
  --sc-title-font-color: var(--color-white);
  --sc-title-font-color-checked: var(--color-white);
  --sc-title-filter: grayscale(1);
  --sc-title-filter-checked: none;
}

.titleCard {
  position: absolute;
  margin: 4px 0;
  width: 100%;
  color: var(--color-white);
  font-weight: 700;
  font-size: 14px;
}

.titleWrapper + div {
  border-radius: 0 0 15px 15px;
}

.priceUnitFull {
  margin: auto 4px 0;
}

.text {
  color: var(--color-pastel);
  font-style: normal;
  font-weight: 400;
  font-size: var(--mg-paywall-card-text-fs);
  line-height: var(--mg-paywall-card-text-lh);
  font-family: var(--font-family-base), serif;
  text-align: left;
}

.textChecked {
  color: var(--color-foreground);
}

.ebookText {
  color: var(--color-foreground-lighter-4);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  font-family: var(--font-family-base), serif;
}

.wrapper {
  grid-gap: 4px;
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
}

.wrapper .defaultDiscount {
  margin: 0;
  padding: 0 4px 0 0;
}

.alignEnd {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.rightBlockContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 4px;
}

.discountPercentage {
  display: inline-flex;
}

.bottomContent {
  margin-bottom: 8px;
  padding: 0 16px 16px;
  width: 100%;
}

.learnMoreHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  --mg-color-on-background: #817b6f;
  color: #817b6f;
}

.collapseIcon {
  display: block;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.unCollapsed {
  transform: rotate(180deg);
}

.paymentButton {
  animation: pulse-button 2s infinite;

  &,
  &:active,
  &:focus,
  &:visited,
  &:hover {
    align-self: center;
    max-width: 327px;
  }
}
