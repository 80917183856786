.container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ffc439;
  height: 48px;
}

.frame {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  opacity: 1;
}

.loaderContainer {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #ffc439;
  width: 100%;
  height: 48px;

  &:hover {
    background-color: #edad2a;
  }
}
